import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "update-account"
    }}>{`Update Account`}</h1>
    <p>{`Updates an Account.`}</p>
    <p><strong parentName="p">{`POST`}</strong>{` `}<inlineCode parentName="p">{`/account/update`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account ID of the Account to retrieve.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account Phone.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account Email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_St_Address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account street address.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_City`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account city.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account state.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account_Zip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. An updated value for account zip.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c"
  "Data": {
    "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c"
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      